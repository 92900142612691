frappe.ready(() => {
	Object.assign(frappe, {
		show_subscription_template_selection: () => {
			if (!frappe.web_form.get_field("subscription_templates")) {
				return
			}

			frappe.call({
				method: "erpnext.venue.doctype.venue_registration_form.venue_registration_form.get_recurring_website_items"
			}).then(r => {
				if (r.message && r.message.length) {
					const template_cards = r.message.map(item => {
						const buttonId = `${frappe.scrub(item.name).replace('"', '\\"')}_subscription`
						const template_card = `
							<div class="card-body">
								<h5 class="card-title">${item.web_item_name}</h5>
								<div class="card-text">${item.short_description || ""}</div>
							</div>
							<div class="card-footer">
								<div class="text-right">
									<button class="btn btn-primary" id="${buttonId}">${__("Select")}</button>
								</div>
							</div>
						`
						let template_image = ''
						if (item.website_image) {
							template_image = `<img class="card-img-top" src="${item.website_image}" alt="${frappe.utils.escape_html(item.web_item_name)}">`
						}

						return `<div class="card subscription-template-card" data-subscription="${frappe.utils.escape_html(item.name)}">
							${template_image}
							${template_card}
							</div>`
					}).join("");

					frappe.web_form.get_field("subscription_templates").wrapper.innerHTML = `<div class="subscription-list">
						${template_cards}
						<style>
							.subscription-template-card {
								width: 18rem;
								cursor: pointer;
							}
							.subscription-template-card.active {
								border-color: var(--primary);
							}
						</style>
					</div>`;

					let prevButton = null;
					const webform = document.getElementsByClassName("web-form")[0];
					webform.addEventListener("click", (e) => {
						const card = e.target.closest(".subscription-template-card");
						if (!card) { return; }

						const itemName = card.getAttribute("data-subscription")
						if (!itemName) { return; }

						const button = card.querySelector("button");
						if (!button) { return; }

						if (prevButton !== null && prevButton !== button) {
							const prevCard = prevButton.closest(".subscription-template-card");
							prevCard.classList.remove("active");
							prevButton.classList.remove("active");
							prevButton.innerText = __("Select");
						}

						card.classList.add("active");
						button.classList.add("active");
						button.innerText = __("Selected");

						prevButton = button;
						frappe.web_form.set_value("website_item", itemName);
					})
				}
			})
		}
	})
})